import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { Helmet } from "react-helmet";
import BackButtonHeader from "../../components/BackButtonHeader";
import "./projects.scss";

function Vertices(): JSX.Element {
  return (
    <div className="project-page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Eshed Margalit | Object Recognition in Modified Line Drawings
        </title>
        <html lang="en" />
      </Helmet>
      <BackButtonHeader title="Object Recognition in Modified Line Drawings" />

      <div className="project-info">
        <p>
          We used a novel voice-triggered data collection system to measure
          human participants' ability to quickly name objects represented by
          modified line drawings. Remarkably, we find that shifting added noise
          contours by even a few pixels can have a devastating effect on object
          recognition ability if that noise contours form an "L-junction". The
          labeled image shown here demonstrates how powerful different kinds of
          vertices and junctions are in guiding our perception of coherent
          volumes.
        </p>
        <StaticImage
          className="project-image"
          src="../../images/projectImages/vertices.png"
          alt="line drawing of a series of interconnected rectangles"
          placeholder="tracedSVG"
          height={250}
          width={250}
        />
      </div>

      <hr />

      <h3>Read the Paper</h3>
      <a
        href="https://link.springer.com/article/10.3758%2Fs13414-019-01840-2"
        target="_blank"
        rel="noreferrer"
      >
        <div className="project-link">
          <div className="title">
            Margalit, E., Herald, S. B., Meschke, E. X., Irawan, I., Maarek, R.,
            & Biederman, I. (2019). Visual noise consisting of X-junctions has
            only a minimal adverse effect on object recognition. Attention,
            Perception, & Psychophysics, 1-8.
          </div>
        </div>
      </a>
    </div>
  );
}

export default Vertices;
